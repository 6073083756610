'use client'

import dynamic from 'next/dynamic'
import { alpha } from '@mui/material/styles'
import { Container, Grid, Typography } from '@mui/material'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const BackgroundAtom = dynamic(() => import('@/components/atoms/background'))
const SearchFormOrganism = dynamic(() => import('@/components/organisms/search-form'))

const HomeTemplate = () => {
	// Variables
	const { t } = useTranslation()

	return (
		<Grid item flexGrow={{ smmd: 1 }} pb={{ xs: 1, smmd: 3 }}>
			<BackgroundAtom />

			<Grid container direction="column" justifyContent="center" alignItems="center" mt={{ smmd: 5, xl: 10 }} px={2}>
				<Typography component="h1" fontSize={{ xs: 30, smmd: 48 }} fontWeight="bold" textAlign="center">
					{t('common:title.welcome')}
				</Typography>

				<Typography component="h2" mt={-1.125} mb={{ xs: 1, smmd: 5 }} fontSize={{ xs: 21, smmd: 24 }} textAlign="center">
					{t('common:subtitle.welcome')}
				</Typography>

				<Container
					maxWidth="smmd"
					sx={{
						p: { xs: 2, smmd: 3 },
						borderRadius: 2.5,
						bgcolor: alpha('#5b41ff', 0.21)
					}}
				>
					<SearchFormOrganism color="primary" />
				</Container>
			</Grid>
		</Grid>
	)
}

export default HomeTemplate
